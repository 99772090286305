import { constants } from "buffer";
import React from "react";
import { Link } from "react-router-dom";
import { PERFIL_USUARIO } from "../../utils/contants";

const Sidebar = ({ PerfilUsuario }: { PerfilUsuario: number }) => {
  var optionsConfiguracion;
  var optionsAnalisis;
  var optionsPersonal;
  var optionsDocumentos;
  var optionsSoporte;
  var optionsIntegracion;

  if (PerfilUsuario === PERFIL_USUARIO.SUPERADMINISTRADOR) {
    optionsConfiguracion = [
      {
        path: "/main/empresa/consulta",
        text: "Clientes",
      },
      {
        path: "/main/usuario/consulta",
        text: "Usuarios",
      },
      {
        path: "/main/cadenamaster/consulta",
        text: "Cadena Catalogo",
      },
      {
        path: "/main/tiendamaster/consulta",
        text: "Tienda Catalogo",
      },
    ];

    optionsDocumentos = [
      {
        path: "/main/documento/consulta",
        text: "Documentos",
      }
    ]

  } else if (PerfilUsuario === PERFIL_USUARIO.ADMINISTRADOR) {
    optionsConfiguracion = [
      {
        path: "/main/usuario/consulta",
        text: "Usuario",
      },
      {
        path: "/main/programacionusuario/consulta",
        text: "Programacion Usuario",
      },
      {
        path: "/main/cargopersona/consulta",
        text: "Cargo Persona",
      },
      {
        path: "/main/canbioclave/consulta",
        text: "Cambio Clave",
      },
      {
        path: "/main/tienda/consulta",
        text: "Tiendas a operar",
      },
      {
        path: "/main/marca/consulta",
        text: "Marcas",
      },
      /*{
        path: "/main/marcaexhibicion/consulta",
        text: "Marcas Exhibicion",
      },*/
      {
        path: "/main/categoria/consulta",
        text: "Categorias",
      },
      {
        path: "/main/subcategoria/consulta",
        text: "Sub Categorias",
      },
      {
        path: "/main/producto/consulta",
        text: "Productos",
      },
    ];

    optionsPersonal = [
      {
        path: "/main/checkinouttienda/consulta",
        text: "Registro Llegada y Salida",
      },
      /*{
        path: "/main/indicadorinternogestion/consulta",
        text: "Gestión Interna",
      },*/
      {
        path: "/main/equipotrabajo/consulta",
        text: "Equipo de Trabajo",
      },
      {
        path: "/main/indicadorsupervision/consulta",
        text: "Supervision",
      },
      {
        path: "/main/indicadormercaderismo/consulta",
        text: "Mercaderismo",
      },
      {
        path: "/main/indicadorpromotoria/consulta",
        text: "Promotoria",
      },
      {
        path: "/main/registrometaventa/consulta",
        text: "Objetivo ventas",
      },
      {
        path: "/main/indicadorvendedores/consulta",
        text: "Vendedores",
      },
    ];
    optionsAnalisis = [
      {
        path: "/main/analisisstock/consulta",
        text: "Stock",
      },
      {
        path: "/main/dashboardstock/consulta",
        text: "Dashboard Stock",
      },
      {
        path: "/main/dashboardauditoria/consulta",
        text: "Dashboard Auditoría",
      },
      {
        path: "/main/analisisprecio/consulta",
        text: "Precio",
      },
      {
        path: "/main/analisisfrente/consulta",
        text: "Frente",
      },
      {
        path: "/main/analisisosa/consulta",
        text: "Osa",
      },
      {
        path: "/main/analisisexhibidores/consulta",
        text: "Exhibidores",
      },
      {
        path: "/main/analisisreportefotografico/consulta",
        text: "Reporte Fotografico",
      },
    ];

    optionsDocumentos = [
      {
        path: "/main/generardocumentos/consulta",
        text: "Generar documentos",
      }
      
    ];

    optionsSoporte = [
      {
        path: "/main/pregunta/consulta",
        text: "Pregunta",
      },

      {
        path: "/main/respuesta/consulta",
        text: "Respuesta",
      },
      {
        path: "/main/incidencia/consulta",
        text: "Incidencia",
      },
      {
        path: "/main/registroalerta/consulta",
        text: "Registro Alerta",
      },
    ];

    optionsIntegracion = [
      {
        path: "/main/integracionstockb2b/consulta",
        text: "Stock B2B",
      },
    ]
  } else if (PerfilUsuario === PERFIL_USUARIO.SUPERVISOR) {
  } else if (PerfilUsuario === PERFIL_USUARIO.MERCADERISTA) {
  } else {
  }

  /*
  var optionsindicadores = [
    {
      path: "/main/indicadorinternogestion/consulta",
      text: "Interno Gestion",
    },
    {
      path: "/main/indicadormercaderismo/consulta",
      text: "Mercaderismo",
    },
    {
      path: "/main/indicadorpromotoria/consulta",
      text: "Promotoria",
    },
    {
      path: "/main/indicadorsupervision/consulta",
      text: "Supervision",
    },
    {
      path: "/main/indicadorvendedores/consulta",
      text: "Vendedores",
    },
  ];

  var optionsAnalisis = [
    {
      path: "/main/analisisstock/consulta",
      text: "Stock",
    },
    {
      path: "/main/analisisprecio/consulta",
      text: "Precio",
    },
    {
      path: "/main/analisisfrente/consulta",
      text: "Frente",
    },
    {
      path: "/main/analisisosa/consulta",
      text: "Osa",
    },
    {
      path: "/main/analisisexhibidores/consulta",
      text: "Exhibidores",
    },
    {
      path: "/main/analisisreportefotografico/consulta",
      text: "Reporte Fotografico",
    },
  ];

  var optionsMantenimientos = [
    {
      path: "/main/marca/consulta",
      text: "Marca",
    },
    {
      path: "/main/categoria/consulta",
      text: "Categoria",
    },
    {
      path: "/main/subcategoria/consulta",
      text: "SubCategoria",
    },
    {
      path: "/main/cliente/consulta",
      text: "Cliente",
    },
    {
      path: "/main/asistencias/consulta",
      text: "Asistencia",
    },

    {
      path: "/main/checkinouttienda/consulta",
      text: "ChechInOutTienda",
    },
    {
      path: "/main/documento/consulta",
      text: "Documento",
    },
    {
      path: "/main/dashboardstock/consulta",
      text: "Dashboardstock",
    },
    {
      path: "/main/equipotrabajo/consulta",
      text: "Equipo Trabajo",
    },
    {
      path: "/main/incidencia/consulta",
      text: "Incidencia",
    },
    {
      path: "/main/marcarepresenta/consulta",
      text: "Marca Representa",
    },
    {
      path: "/main/pregunta/consulta",
      text: "Pregunta",
    },
    {
      path: "/main/registroalerta/consulta",
      text: "Registro Alerta",
    },
    {
      path: "/main/respuesta/consulta",
      text: "Respuesta",
    },
    {
      path: "/main/supervision/consulta",
      text: "Supervision",
    },
    {
      path: "/main/registrometaventa/consulta",
      text: "Registro Meta venta",
    },
  ];*/

  function handleArrow(e: any) {
    let arrowParent = (e.target as HTMLElement).parentElement!.parentElement;
    console.log(arrowParent);
    arrowParent!.classList.toggle("showMenu");
  }

  return (
    <div className="sidebar">
      <div className="logo-details">
        <i className="bx bxl-c-plus-plus"></i>
        <span className="logo_name">TRADE+</span>
      </div>
      <ul className="nav-links">
        {/*<li>
          <a href="#">
            <i className="bx bx-grid-alt"></i>
            <span className="link_name">Configuración</span>
          </a>
          <ul className="sub-menu blank">
            {optionsConfiguracion.map((item: any) => {
              return (
                <li>
                  <Link to={item.path}>{item.text}</Link>
                </li>
              );
            })}
          </ul>
          </li>*/}
        {optionsConfiguracion != null && optionsConfiguracion.length > 0 ? (
          <li key={100}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Configuración</span>
              </a>
              <i
                key={0}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Configuración
                </a>
              </li>
              {optionsConfiguracion != null &&
                optionsConfiguracion.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsPersonal != null && optionsPersonal.length > 0 ? (
          <li key={101}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Personal</span>
              </a>
              <i
                key={2}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Personal
                </a>
              </li>
              {optionsPersonal != null &&
                optionsPersonal.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsAnalisis != null && optionsAnalisis.length > 0 ? (
          <li key={102}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Análisis</span>
              </a>
              <i
                key={3}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Análisis
                </a>
              </li>
              {optionsAnalisis != null &&
                optionsAnalisis.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsDocumentos != null && optionsDocumentos.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Documentos</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Documentos
                </a>
              </li>
              {optionsDocumentos != null &&
                optionsDocumentos.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsSoporte != null && optionsSoporte.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Soporte</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                  Soporte
                </a>
              </li>
              {optionsSoporte != null &&
                optionsSoporte.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {optionsIntegracion != null && optionsIntegracion.length > 0 ? (
          <li key={103}>
            <div className="iocn-link">
              <a href="#">
                <i className="bx bx-collection"></i>
                <span className="link_name">Integración</span>
              </a>
              <i
                key={4}
                className="bx bxs-chevron-down arrow"
                onClick={handleArrow}
              ></i>
            </div>
            <ul className="sub-menu">
              <li>
                <a className="link_name" href="#">
                Integración
                </a>
              </li>
              {optionsIntegracion != null &&
                optionsIntegracion.map((item: any, index: any) => {
                  return (
                    <li>
                      <Link key={index} to={item.path}>
                        {item.text}
                      </Link>
                    </li>
                  );
                })}
            </ul>
          </li>
        ) : (
          <></>
        )}

        {/*<li>
          <div className="iocn-link">
            <a href="#">
              <i className="bx bx-book-alt"></i>
              <span className="link_name">Posts</span>
            </a>
            <i className="bx bxs-chevron-down arrow"></i>
          </div>
          <ul className="sub-menu">
            <li>
              <a className="link_name" href="#">
                Posts
              </a>
            </li>
            <li>
              <a href="#">HTML & CSS</a>
            </li>
            <li>
              <a href="#">Javascript</a>
            </li>
            <li>
              <a href="#">PHP & MySQL</a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-pie-chart-alt-2"></i>
            <span className="link_name">Analytics</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="#">
                Analytics
              </a>
            </li>
          </ul>
        </li>
        <li>
          <a href="#">
            <i className="bx bx-line-chart"></i>
            <span className="link_name">Chart</span>
          </a>
          <ul className="sub-menu blank">
            <li>
              <a className="link_name" href="#">
                Chart
              </a>
            </li>
          </ul>
          </li>*/}
        <li>
          {/*<div className="profile-details">
            <div className="profile-content">
              <img
                src="https://cdn-icons-png.flaticon.com/512/3135/3135715.png"
                alt="Account"
              />
            </div>
            <div className="name-job">
              <div className="profile_name">Prem Shahi</div>
              <div className="job">Web Designer</div>
            </div>
            <i className="bx bx-log-out"></i>
        </div>*/}
        </li>
      </ul>
    </div>
  );
};

export default Sidebar;
