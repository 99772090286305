import * as React from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
  Navigate,
  Outlet,
} from "react-router-dom";
import Main from "./views/main/main-screen";
import Sidebar from "./views/main/sidebar";
import Login from "./views/auth/login-screen";
import Consulta from "./views/components/usuario/consulta-screen";
import ConsultaUsuario from "./views/components/usuario/consulta-screen";
import NuevoUsuario from "./views/components/usuario/nuevo-screen";
import NuevoTiendaMaster from "./views/components/tiendamaster/nuevo-screen";
import ConsultaTiendaMaster from "./views/components/tiendamaster/consulta-screen";
import NuevoCadenaMaster from "./views/components/cademamaster/nuevo-screen";
import ConsultaCadenaMaster from "./views/components/cademamaster/consulta-screen";
import NuevoEmpresa from "./views/components/empresa/nuevo-screen";
import ConsultaEmpresa from "./views/components/empresa/consulta-screen";
import NuevoProducto from "./views/components/producto/nuevo-screen";
import ConsultaProducto from "./views/components/producto/consulta-screen";
import NuevoTienda from "./views/components/tienda/nuevo-screen";
import ConsultaTienda from "./views/components/tienda/consulta-screen";
import NuevoCliente from "./views/components/cliente/nuevo-screen";
import ConsultaCliente from "./views/components/cliente/consulta-screen";
import NuevoMarca from "./views/components/marca/nuevo-screen";
import ConsultaMarca from "./views/components/marca/consulta-screen";
import NuevoCategoria from "./views/components/categoria/nuevo-screen";
import ConsultaCatgoria from "./views/components/categoria/consulta-screen";
import NuevoSubCategoria from "./views/components/subcategoria/nuevo-screen";
import ConsultaSubCategoria from "./views/components/subcategoria/consulta-screen";
import ConsultaDocumento from "./views/components/documento/consulta-screen";
import NuevoDocumento from "./views/components/documento/nuevo-screen";
import ConsultaEquipoTrabajo from "./views/components/equipotrabajo/consulta-screen";
import NuevoEquipoTrabajo from "./views/components/equipotrabajo/nuevo-screen";
import ConsultaProgramacionUsuario from "./views/components/programacionusuario/consulta-screen";
import NuevoProgramacionUsuario   from "./views/components/programacionusuario/nuevo-screen";
import ConsultaPregunta from "./views/components/pregunta/consulta-screen";
import NuevoPregunta   from "./views/components/pregunta/nuevo-screen";
import ConsultaAnalisisExhibidores from "./views/components/analisisexhibidores/consulta-screen";
import ConsultaAnalisisFrente from "./views/components/analisisfrente/consulta-screen";
import ConsultaAnalisisOsa from "./views/components/analisisosa/consulta-screen";
import ConsultaAnalisisPrecio from "./views/components/analisisprecio/consulta-screen";
import ConsultaAnalisisReporteFotoGrafico from "./views/components/analisisreportefotografico/consulta-screen";
import ConsultaAnalisisStock from "./views/components/analisisstock/consulta-screen";
import ConsultaAsistencias from "./views/components/asistencias/consulta-screen";
import ConsultaCanbioClave from "./views/components/canbioclave/consulta-screen";
import NuevoCargoPersona from "./views/components/cargopersona/nuevo-screen";
import ConsultaCargoPersona from "./views/components/cargopersona/consulta-screen";
import ConsultaCheckInOutTienda from "./views/components/checkinouttienda/consulta-screen";
import NuevoGenerarDocumento from "./views/components/generardocumentos/nuevo-screen";
import ConsultaGenerarDocumento from "./views/components/generardocumentos/consulta-screen";
import ConsultaIncidencia from "./views/components/incidencia/consulta-screen";
import ConsultaIndicadorInternoGestion from "./views/components/indicadoresinternogestion/consulta-screen";
import ConsultaIndicadorPromotoria from "./views/components/indicadorpromotoria/consulta-screen";
import ConsultaIndicadorMercaderismo from "./views/components/indicadormercaderismo/consulta-screen";
import ConsultaIndicadorSupervision from "./views/components/indicadorsupervision/consulta-screen";
import ConsultaIndicadorVendedores from "./views/components/indicadorvendedores/consulta-screen";
import NuevoMarcaRepresenta from "./views/components/marcarepresenta/nuevo-screen";
import ConsultaMarcaRepresenta from "./views/components/marcarepresenta/consulta-screen";
import NuevoRegistroAlerta from "./views/components/registroalerta/nuevo-screen";
import ConsultaRegistroAlerta from "./views/components/registroalerta/consulta-screen";
import NuevoRespuesta from "./views/components/respuesta/nuevo-screen";
import ConsultaRespuesta from "./views/components/respuesta/consulta-screen";
import ConsultaSupervision from "./views/components/supervision/consulta-screen";
import ConsultaDashboardstock from "./views/components/dashboardstock/consulta-screen";
import ConsultaRegistroMetaVenta from "./views/components/registrometaventa/consulta-screen";
import NuevoRegistroMetaVenta from "./views/components/registrometaventa/nuevo-screen";
import NuevoMarcaExhibicion from "./views/components/marcaexhibicion/nuevo-screen";
import ConsultaMarcaExhibicion from "./views/components/marcaexhibicion/consulta-screen";
import IntegracionStockB2B from "./views/components/integracionstockb2b/consulta-screen";
import ConsultaDashboardAuditoria from "./views/components/dashboardauditoria/consulta-screen";



export default function App() {
  
  return (
    <AuthProvider>
      {/*<h1>Auth Example</h1>

      <p>
        This example demonstrates a simple login flow with three pages: a public
        page, a protected page, and a login page. In order to see the protected
        page, you must first login. Pretty standard stuff.
      </p>

      <p>
        First, visit the public page. Then, visit the protected page. You're not
        yet logged in, so you are redirected to the login page. After you login,
        you are redirected back to the protected page.
      </p>

      <p>
        Notice the URL change each time. If you click the back button at this
        point, would you expect to go back to the login page? No! You're already
        logged in. Try it out, and you'll see you go back to the page you
        visited just *before* logging in, the public page.
  </p>*/}

      <Routes>
        <Route element={<Layout />}>
          <Route path="/" element={<Login />} />
          {/*<Route
            path="/protected"
            element={
              <RequireAuth>
                <ProtectedPage />
              </RequireAuth>
            }
          />*/}
        </Route>
        <Route path="/main" element={<Main />}>
          <Route path="/main/usuario/consulta" element={<ConsultaUsuario />} />
          <Route path="/main/usuario/mantenimiento/:code" element={<NuevoUsuario />} />
          <Route path="/main/tiendamaster/consulta" element={<ConsultaTiendaMaster />} />
          <Route path="/main/tiendamaster/mantenimiento/:code" element={<NuevoTiendaMaster />} />
          <Route path="/main/cadenamaster/consulta" element={<ConsultaCadenaMaster />} />
          <Route path="/main/cadenamaster/mantenimiento/:code" element={<NuevoCadenaMaster />} />
          <Route path="/main/empresa/consulta" element={<ConsultaEmpresa />} />
          <Route path="/main/empresa/mantenimiento/:code" element={<NuevoEmpresa />} />
          <Route path="/main/producto/consulta" element={<ConsultaProducto />} />
          <Route path="/main/producto/mantenimiento/:code" element={<NuevoProducto />} />
          <Route path="/main/tienda/consulta" element={<ConsultaTienda />} />
          <Route path="/main/tienda/mantenimiento/:code" element={<NuevoTienda />} />
          <Route path="/main/cliente/consulta" element={<ConsultaCliente />} />
          <Route path="/main/cliente/mantenimiento/:code" element={<NuevoCliente />} />
          <Route path="/main/marca/consulta" element={<ConsultaMarca />} />
          <Route path="/main/marcaexhibicion/consulta" element={<ConsultaMarcaExhibicion />} />
          <Route path="/main/marcaexhibicion/mantenimiento/:code" element={<NuevoMarcaExhibicion />} />
          <Route path="/main/marca/mantenimiento/:code" element={<NuevoMarca />} />
          <Route path="/main/categoria/consulta" element={<ConsultaCatgoria />} />
          <Route path="/main/categoria/mantenimiento/:code" element={<NuevoCategoria />} />
          <Route path="/main/subcategoria/consulta" element={<ConsultaSubCategoria />} />
          <Route path="/main/subcategoria/mantenimiento/:code" element={<NuevoSubCategoria />} />
          <Route path="/main/documento/consulta" element={<ConsultaDocumento />} />
          <Route path="/main/documento/mantenimiento/:code" element={<NuevoDocumento />} />
          <Route path="/main/equipotrabajo/consulta" element={<ConsultaEquipoTrabajo />} />
          <Route path="/main/equipotrabajo/mantenimiento/:code" element={<NuevoEquipoTrabajo />} />
          <Route path="/main/programacionusuario/consulta" element={<ConsultaProgramacionUsuario />} />
          <Route path="/main/programacionusuario/mantenimiento/:code" element={<NuevoProgramacionUsuario />} />
          <Route path="/main/pregunta/consulta" element={<ConsultaPregunta />} />
          <Route path="/main/pregunta/mantenimiento/:code" element={<NuevoPregunta />} />
          <Route path="/main/analisisexhibidores/consulta" element={<ConsultaAnalisisExhibidores />} />
          <Route path="/main/analisisfrente/consulta" element={<ConsultaAnalisisFrente />} />
          <Route path="/main/analisisosa/consulta" element={<ConsultaAnalisisOsa />} />
          <Route path="/main/analisisprecio/consulta" element={<ConsultaAnalisisPrecio />} />
          <Route path="/main/analisisreportefotografico/consulta" element={<ConsultaAnalisisReporteFotoGrafico />} />
          <Route path="/main/analisisstock/consulta" element={<ConsultaAnalisisStock />} />
          <Route path="/main/asistencias/consulta" element={<ConsultaAsistencias />} />
          <Route path="/main/canbioclave/consulta" element={<ConsultaCanbioClave />} />
          <Route path="/main/cargopersona/mantenimiento/:code" element={<NuevoCargoPersona />} />
          <Route path="/main/cargopersona/consulta" element={<ConsultaCargoPersona />} />
          <Route path="/main/checkinouttienda/consulta" element={<ConsultaCheckInOutTienda />} />
          <Route path="/main/generardocumentos/mantenimiento/:code" element={<NuevoGenerarDocumento />} />
          <Route path="/main/generardocumentos/consulta" element={<ConsultaGenerarDocumento />} />
          <Route path="/main/incidencia/consulta" element={<ConsultaIncidencia />} />
          <Route path="/main/indicadorinternogestion/consulta" element={<ConsultaIndicadorInternoGestion />} />
          <Route path="/main/indicadorpromotoria/consulta" element={<ConsultaIndicadorPromotoria />} />
          <Route path="/main/indicadormercaderismo/consulta" element={<ConsultaIndicadorMercaderismo />} />
          <Route path="/main/indicadorsupervision/consulta" element={<ConsultaIndicadorSupervision />} />
          <Route path="/main/indicadorvendedores/consulta" element={<ConsultaIndicadorVendedores />} />
          <Route path="/main/marcarepresenta/mantenimiento/:code" element={<NuevoMarcaRepresenta />} />
          <Route path="/main/marcarepresenta/consulta" element={<ConsultaMarcaRepresenta />} />
          <Route path="/main/registroalerta/mantenimiento/:code" element={<NuevoRegistroAlerta />} />
          <Route path="/main/registroalerta/consulta" element={<ConsultaRegistroAlerta />} />
          <Route path="/main/respuesta/mantenimiento/:code" element={<NuevoRespuesta />} />
          <Route path="/main/respuesta/consulta" element={<ConsultaRespuesta />} />
          <Route path="/main/supervision/consulta" element={<ConsultaSupervision />} />
          <Route path="/main/dashboardstock/consulta" element={<ConsultaDashboardstock />} />
          <Route path="/main/dashboardauditoria/consulta" element={<ConsultaDashboardAuditoria />} />
          <Route path="/main/registrometaventa/consulta" element={<ConsultaRegistroMetaVenta />} />
          <Route path="/main/registrometaventa/mantenimiento/:code" element={<NuevoRegistroMetaVenta />} />
          <Route path="/main/integracionstockb2b/consulta" element={<IntegracionStockB2B />} />
        </Route>
      </Routes>
    </AuthProvider>
  );
}

function Layout() {
  return (
    <div>
      {/*<AuthStatus />

      <ul>
        <li>
          <Link to="/">Public Page</Link>
        </li>
        <li>
          <Link to="/protected">Protected Page</Link>
        </li>
  </ul>*/}

      <Outlet />
    </div>
  );
}

interface AuthContextType {
  user: any;
  signin: (user: string, callback: VoidFunction) => void;
  signout: (callback: VoidFunction) => void;
}

let AuthContext = React.createContext<AuthContextType>(null!);

function AuthProvider({ children }: { children: React.ReactNode }) {
  let [user, setUser] = React.useState<any>(null);

  let signin = (newUser: string, callback: VoidFunction) => {
    return fakeAuthProvider.signin(() => {
      setUser(newUser);
      callback();
    });
  };

  let signout = (callback: VoidFunction) => {
    return fakeAuthProvider.signout(() => {
      setUser(null);
      callback();
    });
  };

  let value = { user, signin, signout };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

function useAuth() {
  return React.useContext(AuthContext);
}

function AuthStatus() {
  let auth = useAuth();
  let navigate = useNavigate();

  if (!auth.user) {
    return <p>You are not logged in.</p>;
  }

  return (
    <p>
      Welcome {auth.user}!{" "}
      <button
        onClick={() => {
          auth.signout(() => navigate("/"));
        }}
      >
        Sign out
      </button>
    </p>
  );
}

function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return children;
}

function LoginPage() {
  let navigate = useNavigate();
  let location = useLocation();
  let auth = useAuth();

  let from = location.pathname || "/";

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();

    let formData = new FormData(event.currentTarget);
    let username = formData.get("username") as string;

    auth.signin(username, () => {
      // Send them back to the page they tried to visit when they were
      // redirected to the login page. Use { replace: true } so we don't create
      // another entry in the history stack for the login page.  This means that
      // when they get to the protected page and click the back button, they
      // won't end up back on the login page, which is also really nice for the
      // user experience.
      //navigate(from, { replace: true });
      navigate("/main");
    });
  }

  return (
    <div>
      <p>You must log in to view the page at {from}</p>

      <form onSubmit={handleSubmit}>
        <label>
          Username: <input name="username" type="text" />
        </label>{" "}
        <button type="submit">Login</button>
      </form>
    </div>
  );
}

function PublicPage() {
  return <h3>Public</h3>;
}

function ProtectedPage() {
  return <h3>Protected</h3>;
}

/**
 * This represents some generic auth provider API, like Firebase.
 */
const fakeAuthProvider = {
  isAuthenticated: false,
  signin(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = true;
    setTimeout(callback, 100); // fake async
  },
  signout(callback: VoidFunction) {
    fakeAuthProvider.isAuthenticated = false;
    setTimeout(callback, 100);
  },
};